import Backdrop, { BackdropProps } from 'components/backdrop';
import { Heading, Image, Stack, Text } from 'components/ui';
import { FC, ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import theme, { breakpoints } from 'styles/theme';
import { hexToRGB } from 'utils/helpers';
import { ReactComponent as LogoTextSVG } from './salon-studios-text.svg';

export interface HeroProps extends BackdropProps {
  title: string;
  description?: string | ReactElement;
  cta?: ReactElement;
  fullHeight?: boolean;
  image?: {
    src: string;
    fill?: boolean;
    alt?: string;
  };
}

const LogoText = styled(LogoTextSVG).attrs({ 'aria-hidden': true })`
  position: absolute;
  display: none;
  width: 127px;
  height: 18px;
  background: none;
  transform: rotate(var(--rotate, 0)) translate(54px, -78px);

  @media screen and (min-width: ${breakpoints.small}) {
    display: block;
  }

  * {
    fill: var(--background-color);
  }

  &:first-of-type {
    --rotate: -90deg;

    bottom: 0;
    left: 0;
  }

  &:last-of-type {
    --rotate: 90deg;

    top: 0;
    right: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: var(--padding);

  @media screen and (min-width: ${breakpoints.medium}) {
    &[data-columns='2'] {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;

      > *:first-child {
        position: relative;
        z-index: 1;
        background: none;
      }
    }
  }
`;

const FullImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  @media screen and (min-width: ${breakpoints.medium}) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    width: calc(50% + (var(--padding) / 2));
    height: 100%;
    padding: 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: calc(var(--padding) * 3);
      height: 100%;

      /* Credit to: https://larsenwork.com/easing-gradients/#editor */
      background: linear-gradient(
        to right,
        rgb(var(--gradient-color)) 0%,
        rgba(var(--gradient-color), 0.987) 8.1%,
        rgba(var(--gradient-color), 0.951) 15.5%,
        rgba(var(--gradient-color), 0.896) 22.5%,
        rgba(var(--gradient-color), 0.825) 29%,
        rgba(var(--gradient-color), 0.741) 35.3%,
        rgba(var(--gradient-color), 0.648) 41.2%,
        rgba(var(--gradient-color), 0.55) 47.1%,
        rgba(var(--gradient-color), 0.45) 52.9%,
        rgba(var(--gradient-color), 0.352) 58.8%,
        rgba(var(--gradient-color), 0.259) 64.7%,
        rgba(var(--gradient-color), 0.175) 71%,
        rgba(var(--gradient-color), 0.104) 77.5%,
        rgba(var(--gradient-color), 0.049) 84.5%,
        rgba(var(--gradient-color), 0.013) 91.9%,
        rgba(var(--gradient-color), 0) 100%
      );
      content: '';
    }
  }
`;

const HeroBackdrop = styled(Backdrop)`
  height: var(--height, auto);
  isolation: isolate;
`;

const HeroDescription = styled.div`
  * {
    font-size: var(--font-size-x-small);
  }
`;

const Hero: FC<HeroProps> = ({
  title,
  description,
  backgroundColor,
  flourishTopLeft,
  flourishBottomRight,
  image,
  pattern,
  cta,
  fullHeight,
}) => {
  const bgColor = (backgroundColor && theme.colors[backgroundColor]) || '#fff';
  const gradientColor = useMemo(() => hexToRGB(bgColor), [bgColor]);

  return (
    <HeroBackdrop
      backgroundColor={backgroundColor}
      flourishTopLeft={flourishTopLeft}
      flourishBottomRight={flourishBottomRight}
      pattern={pattern}
      style={{ '--height': fullHeight ? '100%' : undefined }}
      homepage={fullHeight}
    >
      <LogoText />
      <Grid data-columns={image?.src !== undefined ? 2 : 1}>
        <Stack space={{ initial: 2, large: 4, xLarge: 6 }}>
          <Heading level='h1' color='white'>
            {title}
          </Heading>
          <HeroDescription>
            {typeof description === 'string' ? (
              <>
                <Text fontWeight='light' fontSize='xSmall'>
                  {description}
                </Text>
              </>
            ) : (
              description
            )}
          </HeroDescription>
          {cta}
        </Stack>
        {image && !image.fill && (
          <Image
            src={image.src}
            width={1024}
            height={1024}
            alt={image.alt || ''}
            objectFit='cover'
          />
        )}
        {image && image.fill && (
          <FullImageWrapper style={{ '--gradient-color': gradientColor }}>
            <Image
              src={image.src}
              layout='fill'
              objectFit='cover'
              alt={image.alt || ''}
            />
          </FullImageWrapper>
        )}
      </Grid>
      <LogoText />
    </HeroBackdrop>
  );
};

export default Hero;

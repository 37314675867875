import Hero from 'components/hero';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { GetStaticProps, NextPage } from 'next';
import { REVALIDATE_TIME } from 'settings/config';
import { __DEV__ } from 'utils/helpers';
import { homeQuery, SharedData, sharedDataQuery } from 'utils/queries';
import { getHeroProps, usePreviewSubscription } from 'utils/sanity';
import { getClient } from 'utils/sanity-server';

interface HomeProps {
  pageData?: Sanity.Schema.Home;
  sharedData?: SharedData;
  preview?: boolean;
}

const Home: NextPage<HomeProps> = ({ pageData, sharedData, preview }) => {
  const { data: page } = usePreviewSubscription(homeQuery, {
    initialData: pageData,
    enabled: preview,
  });

  const { data: shared } = usePreviewSubscription(sharedDataQuery, {
    initialData: sharedData,
    enabled: preview,
  });

  return (
    <Layout
      showFooter={false}
      preview={preview}
      spaceLinks={shared.spaceInfoPaths}
    >
      <SEO
        title={page.seo?.title}
        description={page.seo?.description}
        image={page.seo?.ogImage}
      />
      <Hero {...getHeroProps(page.hero)} fullHeight={true} />
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps<HomeProps> = async ({
  preview = __DEV__,
}) => {
  const pageData = await getClient(preview).fetch<Sanity.Schema.Home>(
    homeQuery
  );
  const sharedData = await getClient(preview).fetch<SharedData>(
    sharedDataQuery
  );

  return {
    props: {
      pageData,
      sharedData,
      preview,
    },
    revalidate: REVALIDATE_TIME,
  };
};
